import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import SimplePageTemplate from "./simple-page-template";
import {HTMLContent} from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";

const NotariesPage = ({data, location}) => {
    const {frontmatter, html} = data.markdownRemark
    const {title} = useSiteMetadata()

    const meta = {title: `Notariusze | ${title}`}

    return (
        <Layout location={location} meta={meta}>
            <SimplePageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                subheading={frontmatter.subheading}
                contentTitle={frontmatter.contentTitle}
                contentBody={html}
                contentComponent={HTMLContent}
            />
        </Layout>
    )
}

NotariesPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default NotariesPage

export const pageQuery = graphql`
    query NotariesPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "notaries-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                subheading
                contentTitle
            }
            html
        }
    }
`
